<div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="card--title-row">
                <h4 class="card-title">Payments</h4>
                <span class="totalnmbr">Total {{ paymentList?.length }}</span>
                <div class="filter--user text-left mr-2"></div>
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <div class="card--title-button">
                <button class="btn btn-create-payment" (click)="openModal()">
                  Create Payment
                </button>
              </div>
            </div> -->
          </div>
          <div class="table-responsive pt-3">
            <table class="table user-data-table ">
              <thead>
                <tr>
                  <th
                    style="cursor: pointer"
                    (click)="sortByDate(dateOrderDesc)"
                  >
                    Created At<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          dateOrderDesc === false &&
                          sortvalue[0] === 'createdAt',
                        'fa-arrow-up':
                          dateOrderDesc === true && sortvalue[0] === 'createdAt'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('pardnaName')"
                  >
                    Pardna Name
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'pardnaName',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'pardnaName'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('paymentAmount')"
                  >
                    Amount
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' &&
                          sortvalue[0] == 'paymentAmount',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' &&
                          sortvalue[0] == 'paymentAmount'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('status')">
                    Status
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'status',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'status'
                      }"
                    ></i>
                  </th>

                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('firstName')"
                  >
                    User<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'firstName',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'firstName'
                      }"
                    ></i>
                  </th>
                  <th>Enviroment</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  *ngIf="paymentList?.length != 0"
                  ngFor
                  let-payment
                  [ngForOf]="
                    paymentList
                      | SortingFilter : sortvalue
                      | paginate
                        : {
                            itemsPerPage: 10,
                            currentPage: p,
                            totalItems: total
                          }
                  "
                  let-i="index"
                >
                  <!-- <tr [ngClass]="{
                    'sandbox-row':payment?.paymentMode =='sandbox',
                    'live-row':payment?.paymentMode =='live'
                  }"> -->
                  <tr>
                    <td>
                      {{
                        changeDateFormat(
                          payment?.apiResponse?.results[0]?.created_at
                        ) || ""
                      }}
                    </td>
                    <td>
                      <a
                        class="user--name"
                        routerLink="/admin/pardna/{{ payment?.pardnaId }}"
                        target="_blank"
                      >
                        {{ payment?.pardna?.name }}</a
                      >
                    </td>
                    <td>
                      {{
                        "£" + (payment?.apiResponse?.results[0]?.amount / 100).toFixed(2) ||
                          ""
                      }}
                    </td>
                    <td>
                      <a
                        class="user--name"
                        routerLink="/admin/payment/{{ payment?.id }}"
                        target="_blank"
                      >
                        {{ payment?.apiResponse?.results[0]?.status || "" }}
                      </a>
                    </td>

                    <td>
                      <a
                        class="user--name"
                        routerLink="/admin/user/{{ payment?.user?.id }}"
                        target="_blank"
                        >{{ payment?.user?.firstName }}
                        {{ payment?.user?.lastName }}</a
                      >
                    </td>
                    <td [ngClass]="{
                      'sandbox-row':payment?.paymentMode =='sandbox',
                      'live-row':payment?.paymentMode =='live'
                    }">{{payment?.paymentMode}}</td>
                    <td>
                      <button
                        class="btn btn-outline-brand"
                        (click)="deletePayment(payment.id)"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </ng-template>
                <tr *ngIf="paymentList?.length == 0">
                  <td colspan="6" class="text-center">No documents found !</td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="paymentList?.length > 0"
              class="pagination mt-4 float-right"
            >
              <pagination-controls
                (click)="clickPagination()"
                [maxSize]="3"
                (pageChange)="getPage($event)"
                directionLinks="true"
                previousLabel="Previous"
                nextLabel="Next"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
