import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import * as _ from "lodash";
import * as moment from "moment";
import Swal from "sweetalert2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";
@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit {
  paymentList;
  p: number = 1;
  sortvalue = ["createdAt", "desc"];
  sort: string = "asc";
  dateOrderDesc: boolean = true;
  total = "0";
  startAfter = undefined;
  incrementFlag = true;
  modalRef: BsModalRef;
  constructor(
    private fireBaseService: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    // this.getPaymentsList();
    this.getPaymentsList2();
    this.getCount();
  }
  getPage(page) {
    if (this.p < page) {
      this.incrementFlag = false;
    } else {
      this.incrementFlag = true;
    }
    this.p = page;
    this.getPaymentsList2();
  }
  async getCount() {
    this.total = (await this.fireBaseService.getPaymentLength()).toString();
  }
  async getPaymentsList() {
    try {
      //get pardnas
      const pardnas = {};
      await this.fireBaseService
        .getPardna()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            pardnas[doc.id] = data;
          });
        })
        .catch(
          function (error) {
            this.loaderService.hide();
          }.bind(this)
        );

      //get users
      const users = {};
      await this.fireBaseService
        .getUsers()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            data["id"] = doc.id;
            users[data.userId] = data;
          });
        })
        .catch(
          function (error) {
            this.loaderService.hide();
          }.bind(this)
        );
      const payments = [];
      await this.fireBaseService
        .getPayments()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              const data = doc.data();
              data["id"] = doc.id;
              data["pardna"] = pardnas[data["pardnaId"]];
              data["user"] = users[data["userId"]];
              payments.push(data);
            });

            this.paymentList = [...payments];
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
    }
    this.sortvalue = ["createdAt", "asc"];
    this.sortByDate("asc");
  }
  async getPaymentsList2() {
    try {
      // const paymentListRef = this.paymentList;
      const { payments, last } =
        await this.fireBaseService.getPaginatedPayments(
          this.p * 10,
          this.startAfter,
          this.incrementFlag
        );
      const pardna = await this.fireBaseService.getPardnaByArray(
        payments.map((obj) => obj.pardnaId)
      );
      const users = await this.fireBaseService.getUsersByArray(
        payments.map((obj) => obj.userId)
      );
      payments.forEach(function (doc) {
        doc["pardna"] = pardna[doc["pardnaId"]];
        doc["user"] = users[doc["userId"]];
      });

      this.startAfter = last;
      this.paymentList = payments;
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
    }
  }

  clickPagination() {
    window.scroll(0, 0);
  }

  changeDateFormat(date) {
    return date && date.seconds
      ? moment.unix(date.seconds).format("MMM DD, YYYY")
      : date
      ? moment(date).isValid()
        ? moment(date).format("MMM DD, YYYY")
        : date
      : "";
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, this.sort];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [value, "desc"])
        : (this.sortvalue = [value, "asc"]);
    }
  }
  sortByDate(value) {
    if (value) {
      this.sortvalue = ["createdAt", "asc"];
      // asc
      let filtered1 = this.paymentList.filter(
        (item) => !item.paymentList?.updatedAt
      );
      let filtered = this.paymentList.filter(
        (item) => item.paymentList?.updatedAt
      );
      this.paymentList = _.orderBy(filtered1, ["updatedAt.seconds"], ["asc"]);
      this.paymentList = [...this.paymentList, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    } else {
      // desc
      this.sortvalue = ["createdAt", "desc"];
      let filtered1 = this.paymentList.filter(
        (item) => !item.paymentList?.updatedAt
      );
      let filtered = this.paymentList.filter(
        (item) => item.paymentList?.updatedAt
      );
      this.paymentList = _.orderBy(filtered1, ["updatedAt.seconds"], ["desc"]);
      this.paymentList = [...this.paymentList, ...filtered];
      this.dateOrderDesc = !this.dateOrderDesc;
    }
  }

  deletePayment(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "This will delete the payment!",
      icon: "warning",
      showLoaderOnConfirm: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.fireBaseService.getPaymentByRef(id).then((querySnapshot) => {
            const data = querySnapshot.data();
            this.fireBaseService
              .deletePayment(id)
              .then(() => {
                this.fireBaseService
                  .updatePardna(data.pardnaId, data.userId)
                  .then(() => {
                    this.loaderService.hide();
                    Swal.fire("Payment Deleted Successfully!").then(() => {
                      this.getPage(this.p);
                      this.getCount();
                    });
                  })

                  .catch(() => {
                    this.loaderService.hide();
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Unable to delete payment!",
                    });
                  });
              })
              .catch(() => {
                this.loaderService.hide();
                Swal.fire({
                  icon: "error",
                  title: "Osops...",
                  text: "Unable to delete payment!",
                });
              });
            this.loaderService.hide();
          });
        }
      })
      .catch((error) => {
        this.loaderService.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Payment Does not exist",
        });
      });
  }

  openModal() {
    this.modalRef = this.modalService.show(PaymentModalComponent);
  }
}
