import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase";
// import * as admin from "firebase-admin";
import { Subject, BehaviorSubject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: "root",
})
export class FirebaseQueryService {
  DB = firebase.firestore();

  constructor(
    private db: AngularFirestore,
    private loaderService: NgxSpinnerService
  ) {}

  createUser(email, password) {
    this.loaderService.show();
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  signInUser(email, password) {
    this.loaderService.show();
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  currentUser() {
    this.loaderService.show();
    return firebase.auth().currentUser;
  }

  getPardna() {
    this.loaderService.show();
    return this.DB.collection("Pardnas").get();
  }

  getUsers() {
    this.loaderService.show();
    return this.DB.collection("users").get();
  }

  getPayments() {
    this.loaderService.show();
    return this.DB.collection("payments").where("deleted", "==", false).get();
  }

  async getNestedPayments() {
    const paymentsSnapshot = await this.DB.collection("payments").get();
    const payments = [];
    paymentsSnapshot.forEach((payment) => {
      payments.push({
        id: payment.id,
        ...payment.data(),
      });
    });
    console.log(payments);
  }

  getPardnaByRef(id) {
    this.loaderService.show();
    return this.DB.collection("Pardnas").doc(id).get();
  }

  getUserById(id) {
    this.loaderService.show();
    return this.DB.collection("users").where("userId", "==", id).get();
  }

  getUserByDoc(id) {
    this.loaderService.show();
    return this.DB.collection("users").doc(id).get();
  }

  updateUserSandbox(ref, sandbox) {
    return this.DB.collection("users")
      .doc(ref)
      .set({ sandbox: sandbox }, { merge: true });
  }

  getUserPardnaGroups(id) {
    return this.DB.collection("Pardnas")
      .where("group", "array-contains", id)
      .get();
  }

  updatePardnaSandbox(ref, sandbox) {
    return this.DB.collection("Pardnas")
      .doc(ref)
      .set({ sandbox: sandbox }, { merge: true });
  }

  getPreviousStartingPoint = async (startingPoint) => {
    try {
      const payments = [];
      let query = await this.DB.collection("payments")
        .orderBy("updatedAt", "asc")
        .startAt(startingPoint)
        .limit(20)
        .get();

      query.forEach((doc) => {
        payments.push({ id: doc.id, ...doc.data() });
      });
      return payments.splice(9);
    } catch (error) {
      console.log(123, error);
    }
  };

  async getPaginatedPayments(startAt, last, incrementFlag) {
    try {
      const payments = [];
      let query = this.DB.collection("payments")
        .where("deleted", "==", false)
        .orderBy("updatedAt", "desc")
        .limit(10);

      if (last && !incrementFlag) {
        query = query.startAt(last);
      } else if (last && incrementFlag) {
        const r = await this.getPreviousStartingPoint(last);
        console.log(r);
        query = query.startAt(r[r.length - 1].updatedAt).endAt(last);
      }
      const result = await query.get();
      result.forEach((doc) => {
        payments.push({ id: doc.id, ...doc.data() });
      });
      last = payments[payments.length - 1]?.updatedAt || 0;
      return { payments, last };
    } catch (error) {
      console.log(123, error);
    }
  }

  async getPardnaByArray(ids) {
    try {
      const pardna = {};
      this.loaderService.show();
      const result = await this.DB.collection("Pardnas")
        .where(firebase.firestore.FieldPath.documentId(), "in", ids)
        .get();

      result.forEach((doc) => {
        pardna[doc.id] = { id: doc.id, ...doc.data() };
      });

      return pardna;
    } catch (error) {
      console.log(123, error);
    }
  }

  async getUsersByArray(ids) {
    try {
      const users = {};
      this.loaderService.show();
      const result = await this.DB.collection("users")
        .where("userId", "in", ids)
        .get();

      result.forEach((doc) => {
        const data = doc.data();
        users[data.userId] = { id: doc.id, ...doc.data() };
      });

      return users;
    } catch (error) {
      console.log(123, error);
    }
  }

  async getPaymentLength() {
    this.loaderService.show();
    const coll = (
      await this.DB.collection("payments").where("deleted", "==", false).get()
    ).docs.length;
    return coll;
  }

  getPaymentByRef(id) {
    this.loaderService.show();
    return this.DB.collection("payments").doc(id).get();
  }
  getPaymentByUserId(id) {
    return this.DB.collection("payments").where("id", "==", id).get();
  }

  deletePayment(id) {
    this.loaderService.show();

    return this.DB.collection("payments").doc(id).update({ deleted: true });
  }
  async updatePardna(id, userId) {
    this.loaderService.show();
    const pardna = (await this.DB.collection("Pardnas").doc(id).get()).data();
    console.log(123, pardna);
    const payment_pending = [...pardna.payment_pending, userId];
    const payments = { ...pardna.payments };
    delete payments[userId];
    return this.DB.collection("Pardnas").doc(id).update({
      payment_pending,
      payments,
    });
  }
}
