// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: "http://localhost:3000/api/v1",
  baseUrl: "https://api.pardna.com/api/v1",
  // baseUrl : 'http://18.221.151.255:3000/api/v1'
  // baseUrl : 'http://3.130.74.228:3000/api/v1'
};

export const firebaseConfig = {
  apiKey: "AIzaSyAIbIymNzcsKgDFnFbXM4m3jdINg2qlVn4",
  authDomain: "pardna-76d2f.firebaseapp.com",
  databaseURL: "https://pardna-76d2f.firebaseio.com",
  projectId: "pardna-76d2f",
  storageBucket: "pardna-76d2f.appspot.com",
  messagingSenderId: "688263389299",
  appId: "1:688263389299:web:dedaf3ea6904112ea50a06",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
