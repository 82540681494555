import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";

const routes: Routes = [
  // { path: '', component: LoginComponent},
  { path: "success", component: PaymentSuccessComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
