import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModule } from "../shared/shared.module";

import { AdminRoutingModule } from "./admin-routing.module";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { NgxPaginationModule } from "ngx-pagination";
import { NgSelectModule } from "@ng-select/ng-select";

/** Components */
import { AdminComponent } from "./admin.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FooterComponent } from "../common/footer/footer.component";
import { HeaderComponent } from "../common/header/header.component";
import { NavBarTopComponent } from "../common/nav-bar-top/nav-bar-top.component";
import { PardnaComponent } from "./pardna/pardna.component";
import { ActivePardnasComponent } from "./active-pardnas/active-pardnas.component";
import { PardnaDetailsComponent } from "./pardna-details/pardna-details.component";
import { UserlistComponent } from "./userlist/userlist.component";
import { UserdetailComponent } from "./userdetail/userdetail.component";
import { PaymentsComponent } from "./payments/payments.component";
import { PaymentDetailsComponent } from "./payment-details/payment-details.component";
import { PaymentModalComponent } from "./payment-modal/payment-modal.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    NavBarTopComponent,
    AdminComponent,
    DashboardComponent,
    PardnaComponent,
    ActivePardnasComponent,
    PardnaDetailsComponent,
    UserlistComponent,
    UserdetailComponent,
    PaymentsComponent,
    PaymentDetailsComponent,
    PaymentModalComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    AccordionModule.forRoot(),
    NgxPaginationModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
})
export class AdminModule {}
