import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiServiceService } from "src/app/core/api-service/api-service";
@Component({
  selector: "app-payment-modal",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.scss"],
})
export class PaymentModalComponent implements OnInit /*OnDestroy*/ {
  pardnaId;
  userId;
  registerForm: FormGroup;
  formSubmitted = false;
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private httpClient: ApiServiceService
  ) {}
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      amount: [null, [Validators.required, Validators.min(1),Validators.pattern("^[0-9]*$")]],
      type: [null, [Validators.required]],
    });
  }
  // OnDestroy() {
  //   this.bsModalRef.hide();
  // }
  close() {
    this.bsModalRef.hide();
  }
  async onSubmit() {
    console.log(this.pardnaId);
    console.log(123);
    this.formSubmitted = true;
    console.log(123, this.registerForm.value);
    const url =
      this.registerForm.value.type == "live"
        ? "getAuthUrl"
        : "getAuthUrlSandbox";

    this.httpClient
      .postRequest(url, {
        pardnaId:this.pardnaId,
        userId:this.userId,
        beneficiary_name: "Pardna",
        beneficiary_reference: "pardna101",
        beneficiary_sort_code: "306565",
        beneficiary_account_number: "57210360",
        // remitter_reference: "remitter101",
        remitter_reference: this.pardnaId.substring(0,18),
        amount: this.registerForm.value.amount*100,
      })
      .subscribe((res: any) => {
        console.log("auth_uri: "+res.auth_uri)
        window.open(res.auth_uri);
        this.close();
      });
  }
}
