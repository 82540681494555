<nav class="bottom-navbar">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/dashboard">
              <span class="menu-title">Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/activepardna">
              <span class="menu-title">Pardnas</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/users">
              <span class="menu-title">Users</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/payments">
              <span class="menu-title">Payments</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</nav>
